import React from 'react';
import FirstPerson from './FirstPerson';
import SecondPerson from './SecondPerson';

function HookStateChat() {
	return (
		<div>
			<FirstPerson />
			<SecondPerson />
		</div>
	);
}

export default HookStateChat;